.car-list-item {
  display: flex;
}

.car-list-item-left {
  flex-basis: 125px;
}

.car-list-item-right {
  flex: 1;
}

.car-list-item-content-top {
  display: flex;
  flex: 1;
}

.car-list-item-content-bottom {
  display: flex;
  flex: 1;
}

.car-list-item-content-top-left {
  flex: 1;
  font-size: 18px;
  padding-bottom: 6px;
}

.car-list-item-content-top-right {
  flex: 1;
  padding-top: 6px;
}
