@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;600&display=swap");
@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  position: relative;
  width: 30px;
  float: left;
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.3);
}

.logo-name {
  float: left;
  margin-left: 10px;
}

.title {
  font-size: 24px;
  font-family: "Raleway", sans-serif;
  margin-top: 11px;
  line-height: 30px;
}

.subtitle {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 10px;
  color: #99a1ab;
}

.content {
  border-top: 1px solid #e4e8f0;
  background: white;
  padding: 0 50px;
}

.ant-layout-header {
  background: white;
}

.ant-list-lg .ant-list-item {
  padding: 16px 0px;
}

.list-item-img {
  float: left;
}
